/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

// Back-to-top
$('body').prepend('<span href="#" class="back-to-top"><a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">vertical_align_top</i></a></span>');

if((document.documentElement.clientWidth < 600)){
  $('body').prepend('<span href="#" class="send-cita-mobile"><a href="#modal1">SOLICITAR CITA</a></span>');
}



//scrolling control for various elements
// Check the initial Position of the Sticky Header


//var menuInterno = $('#menu-interno');
var menuInterno = $('.fixable-nav');

var menuInternoTop = 99999;
var menuInternoHeight = 0;
$('#post-fixed').css('height', 0);
if (menuInterno.length) {
  //$('#post-fixed').css('height', 20);
  menuInternoTop = menuInterno.offset().top;
  menuInternoHeight = menuInterno.outerHeight();

}

var amountScrolled = 300;

// var shareButtons = $('#share-buttons');

// var innerContent = $('#inner-content');

// var shareButtonsTop = 99999;
// var shareButtonsBottom = 0;

// if (shareButtons.length){


//  shareButtonsTop = shareButtons.offset().top;
//  shareButtonsBottom = innerContent.offset().top + innerContent.outerHeight(true);

// }





$(window).scroll(function() {


// menu interno fixed

    if( $(window).scrollTop() >  menuInternoTop ) {
      menuInterno.addClass("nav-fixed");
      $('#post-fixed').css('height', menuInternoHeight);
    } else {
      menuInterno.removeClass("nav-fixed");
      $('#post-fixed').css('height', 0);
    }


  //back to top

  if ($(window).scrollTop() > amountScrolled) {
    $('span.back-to-top').fadeIn('slow');

  } else {
    $('span.back-to-top').fadeOut('slow');
  }

  if ( ($(window).scrollTop() > amountScrolled) && (document.documentElement.clientWidth < 600)) {

    $('span.send-cita-mobile').fadeIn('slow');
    
  } else {

    $('span.send-cita-mobile').fadeOut('slow');
  }

//share-buttons

  // if( $(window).scrollTop() > shareButtonsTop  && $(window).scrollTop() < shareButtonsBottom) {
  //   shareButtons.addClass("share-fixed");
  //   shareButtons.css('max-width', '70px');
  // } else {
  //   shareButtons.removeClass("share-fixed");
  // }


});



$('span.back-to-top, a.simple-back-to-top').on('click',function() {
  var body = $('body,html');
  body.stop().animate({ scrollTop: 0 }, 500 , function() {
    console.log('Finished animating ' + body.height());
  });
  return false;
});



        // anchors with scrolling
//$('a[href*=#]:not([href=#])').click(function() {



// $('a.toanchor').click(function() {
//   event.preventDefault();
//   $(this).addClass('active');
//   $(this).parent().siblings().children().removeClass('active');

// if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') || location.hostname === this.hostname) {
// var target = $(this.hash);
// target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
// if (target.length) {
// $('html,body').animate({
// scrollTop: target.offset().top - 80
// }, 1000);
// return false;
// }
// }
// });

$('.scrollspy').scrollSpy({scrollOffset: 85});

// $('ul.tabsx').tabs({
//    swipeable : true
//  });

// $('ul.tabs').tabs({
//   swipeable : true
// });

//$("tabs-content.carousel").style.height = window.innerHeight + "px";


function autoplay($elemento) {
    $elemento.carousel('next');
    setTimeout(autoplay, 7000);
}
//$('#opiniones.carousel.carousel-slider').carousel({fullWidth: true, indicators: true});
$('#opiniones.carousel.carousel-slider').carousel({indicators: false});
autoplay($('#opiniones.carousel'));

//$('.custom-slider.carousel.carousel-slider').carousel({fullWidth: true, indicators: true});
$('.custom-slider.carousel.carousel-slider').carousel({indicators: false});
autoplay($('.custom-slider.carousel.carousel-slider'));

$('.slider').slider({indicators:false, interval: 7000});
//$('.materialboxed').materialbox();

$(".button-collapse").sideNav();


$('.dropdown-button.main-menu-item').dropdown({
    inDuration: 300,
    outDuration: 225,
    constrain_width: true, // Does not change width of dropdown to that of the activator
    hover: true, // Activate on hover
    belowOrigin: true, // Displays dropdown below the button
    alignment: 'left' // Displays dropdown with edge aligned to the left of button
});
// nested dropdown initialization
$('.dropdown-button.sub-menu-item').dropdown({
    inDuration: 300,
    outDuration: 225,
    constrain_width: false, // Does not change width of dropdown to that of the activator
    hover: true, // Activate on hover
    gutter: ($('.dropdown-content').width() * 3) / 3.05 + 3, // Spacing from edge
    belowOrigin: false, // Displays dropdown below the button
    alignment: 'left' // Displays dropdown with edge aligned to the left of button
});


  $("[href='#modal1']").on('click',function() {
    $('.modal').modal('open');
  });


  $('.modal').modal({
      dismissible: true, // Modal can be dismissed by clicking outside of the modal
      opacity: 0.5, // Opacity of modal background
      inDuration: 300, // Transition in duration
      outDuration: 300, // Transition out duration
      startingTop: '10%', // Starting top style attribute
      endingTop: '0', // Ending top style attribute
      ready: function(modal, trigger) { // Callback for Modal open. Modal and trigger parameters available.
        //alert("Ready");
        //console.log(modal, trigger);
      },
      complete: function() { //alert('Closed');
       } // Callback for Modal close
    }
  );


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
